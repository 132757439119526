import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Header } from "./styles";
import Spinner from "../Spinner";

const CollapsibleHeader = ({ header, disabled, show, setShow }) => {
  return (
    <Header show={show} onClick={() => !disabled && setShow((prev) => !prev)}>
      <h3>{header}</h3>
      {disabled ? <Spinner inline /> : <FaChevronDown />}
    </Header>
  );
};

export default CollapsibleHeader;
