import {
  formatNumber,
  getDateWithHumanReadableTime,
  OverflowContainer,
  Table,
} from "common";

const TableView = ({ saleOrders = [], isCustomer }) => {
  return (
    <OverflowContainer>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Order Date</th>
            <th>Shipping Date</th>
            <th>Order Number</th>
            <th>{isCustomer ? "Brands" : "Customer"}</th>
            <th>Sales Rep</th>
            <th>Quantity</th>
            <th>Amount</th>
            <th>Paid</th>
            <th>Outstanding</th>
          </tr>
        </thead>
        <tbody>
          {saleOrders.map((so) => {
            const outstanding = so.Amount - so.PaymentAmount;
            return (
              <tr key={so.id}>
                <td>{so.Id}</td>
                <td>{getDateWithHumanReadableTime(so.OrderDate)}</td>
                <td>{getDateWithHumanReadableTime(so.ShippingDate)}</td>
                <td>{so.OrderNumber}</td>
                <td>
                  {isCustomer
                    ? so.Brands.map((b) => b.name).join(", ")
                    : so.CustomerName}
                </td>
                <td>{so.SalesRepresentative}</td>
                <td>{formatNumber(so.TotalQty.toFixed())}</td>
                <td>${formatNumber(so.Amount.toFixed())}</td>
                <td>${formatNumber(so.PaymentAmount.toFixed())}</td>
                <td>${formatNumber(outstanding.toFixed())}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </OverflowContainer>
  );
};

export default TableView;
