import { OverflowContainer } from "common";
import styled from "styled-components";

const Container = styled(OverflowContainer)`
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-gap: 1rem;
`;

const ViewContainer = styled.div`
  > div {
    font-size: ${({ inline }) => (inline ? "0.7rem" : "0.8rem")};
  }
`;

export { Container, ViewContainer };
