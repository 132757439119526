import { OverflowContainer, Table } from "common";
import BrandRow from "./BrandRow";

const Form = ({ brands = [], crmBrands = [], refetch }) => {
  return (
    <OverflowContainer>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>HBA Brand</th>
            <th>Matching CRM Brands</th>
            <th style={{ width: "1%" }} />
          </tr>
        </thead>
        <tbody>
          {brands.map((b) => {
            return (
              <BrandRow
                key={b.id}
                crmBrands={crmBrands}
                brand={b}
                initialMatches={b.matchingCrmBrands.map((c) => c.id)}
                refetch={refetch}
              />
            );
          })}
        </tbody>
      </Table>
    </OverflowContainer>
  );
};

export default Form;
