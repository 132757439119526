import { CheckmarkOutline } from "@carbon/icons-react";
import { formatNumber, getDateWithHumanReadableTime } from "common/functions";
import { format } from "date-fns";

const renderElement = ({
  value,
  isDateTime,
  isCurrency,
  isDate,
  isHtml,
  isHumanReadableDate,
}) =>
  value === null || value === undefined ? (
    "-- -- -- --"
  ) : isDateTime ? (
    format(new Date(value), "MMM dd, yyyy HH:mm")
  ) : isDate && value ? (
    format(new Date(value), "MMM dd, yyyy")
  ) : isHumanReadableDate && value ? (
    getDateWithHumanReadableTime(value)
  ) : typeof value === "boolean" ? (
    value ? (
      <CheckmarkOutline />
    ) : null
  ) : isCurrency ? (
    `$${formatNumber(value, true)}`
  ) : isHtml ? (
    <div dangerouslySetInnerHTML={{ __html: value }} />
  ) : (
    value
  );

export { renderElement };
