import validationSchema from '../validationSchema';

const formikSchema = {
  initialValues: {
    name: '',
  },
  validationSchema,
};

export default formikSchema;
