import {
  routing,
  linkPlaceholders,
  Button,
  generateLinkWithParams,
  Link,
} from "common";
import { useParams } from "react-router-dom";

const ViewOrdersLink = () => {
  const { brandId } = useParams();

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandOrders, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button fit>View Orders</Button>
    </Link>
  );
};

const ViewOffersLink = () => {
  const { brandId } = useParams();

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandOffers, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button fit>View Offers</Button>
    </Link>
  );
};

const ViewBrandLink = () => {
  const { brandId } = useParams();

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrand, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button fit>View Brand</Button>
    </Link>
  );
};

const ViewCustomersLink = () => {
  const { brandId } = useParams();

  return (
    <Link
      to={generateLinkWithParams(routing.crm.viewBrandCustomers, {
        [linkPlaceholders.brandId]: brandId,
      })}
    >
      <Button fit>View Customers</Button>
    </Link>
  );
};

export { ViewBrandLink, ViewCustomersLink, ViewOrdersLink, ViewOffersLink };
