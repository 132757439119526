import {
  generateLinkWithParams,
  Link,
  linkPlaceholders,
  OverflowContainer,
  routing,
  Table,
  useModalContext,
} from "common";
import { TableNoteView } from "modules/Crm/common";
import { GrNotes } from "react-icons/gr";
import {
  AgingView,
  ChangeFollowUpDate,
  InvoiceView,
  Notes,
  PoView,
  PricesheetDownloads,
  Reject,
  SoView,
} from "../common";

const TableView = ({
  brands = [],
  isCustomer,
  nameKey,
  updateCustomerBrandState,
}) => {
  const { setModalContent } = useModalContext();
  return (
    <OverflowContainer>
      <Table>
        <thead>
          <tr>
            <th>{isCustomer ? "Brand" : "Customer"}</th>
            <th>Latest Invoice</th>
            <th>Latest SO</th>
            <th>Latest PO</th>
            <th style={{ width: "200px" }}>Follow Up Date</th>
            <th>Aging</th>
            <th>Latest Note</th>
            <th style={{ width: "1%" }} />
            <th style={{ width: "1%" }} />
            <th style={{ width: "1%" }} />
          </tr>
        </thead>
        <tbody>
          {brands.map((brand) => {
            const name = brand[nameKey];
            const mutationProps = {
              name,
              brand,
              updateCustomerBrandState,
            };
            const searchParams = isCustomer
              ? `customer=${brand.customerId}`
              : `brand=${brand.brandId}`;
            return (
              <tr key={brand.id}>
                <td>
                  <Link
                    target="_blank"
                    to={`${generateLinkWithParams(
                      isCustomer
                        ? routing.brands.view
                        : routing.crm.viewCustomer,
                      {
                        [linkPlaceholders.brandId]: brand.brandId,
                        [linkPlaceholders.crmCustomerId]: brand.customerId,
                      }
                    )}?${searchParams}`}
                  >
                    {name}
                  </Link>
                </td>
                <td>{<InvoiceView invoice={brand.latestInvoice} inline />}</td>
                <td>{<SoView so={brand.latestSaleOrder} inline />}</td>
                <td>{<PoView so={brand.latestPurchaseOrder} inline />}</td>
                <td>
                  <ChangeFollowUpDate {...mutationProps} inline />
                </td>
                <td>
                  <AgingView {...mutationProps} />
                </td>
                <td>
                  <TableNoteView notes={brand.notes} />
                </td>
                <td>
                  <GrNotes
                    cursor="pointer"
                    onClick={() =>
                      setModalContent(
                        <div
                          style={{
                            width: "400px",
                            height: "500px",
                            overflow: "auto",
                          }}
                        >
                          <Notes {...mutationProps} />
                        </div>
                      )
                    }
                  />
                </td>
                <td>
                  <PricesheetDownloads brand={brand} />
                </td>
                <td>
                  <Reject {...mutationProps} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </OverflowContainer>
  );
};

export default TableView;
