import { orderBy } from "lodash";

const { RowFlex, getDateWithHumanReadableTime } = require("common");

const TableNoteView = ({ notes: n = [] }) => {
  const notes = orderBy(n, (d) => new Date(d.date), "desc");
  const latestNote = notes[0];
  return (
    <RowFlex style={{ width: "100%" }}>
      {latestNote && (
        <div style={{ fontSize: "0.7rem", width: "100%" }}>
          <RowFlex extend style={{ marginBottom: "0.5rem" }}>
            <strong>{latestNote.username || latestNote.user?.name}</strong>
            <div>{getDateWithHumanReadableTime(latestNote.date, true)}</div>
          </RowFlex>
          <div dangerouslySetInnerHTML={{ __html: latestNote.value }}></div>
        </div>
      )}
    </RowFlex>
  );
};

export { TableNoteView };
