import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFirstRender from "./useFirstRender";
import { useFilters, usePagination } from "common/components";

const useFiltersAndPagination = ({
  total,
  filterConfig,
  recallFunction = () => {},
  initialLimit = 10,
  initialFilters = null,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const currentTab = queryParams.get("tab");
  const initialPage = parseInt(queryParams.get("page"), 10) || 1;
  const initialLimitFromURL =
    parseInt(queryParams.get("limit"), 10) || initialLimit;
  const calculatedInitialSkip = (initialPage - 1) * initialLimitFromURL;

  const [urlLimit, setUrlLimit] = useState(initialLimitFromURL);
  const [urlSkip, setUrlSkip] = useState(calculatedInitialSkip);
  const [currentPage, setCurrentPage] = useState(initialPage); // State to track current page

  const { paginationComponent, limit, skip, setSkip, setLimit } = usePagination(
    {
      total,
      initialLimit: urlLimit,
      initialSkip: urlSkip,
    }
  );

  useEffect(() => {
    // This effect synchronizes the URL with the internal state
    const page = Math.floor(skip / limit) + 1;
    const newQueryParams = new URLSearchParams(location.search);

    newQueryParams.set("limit", limit.toString());
    newQueryParams.set("page", page.toString());
    if (currentTab) {
      newQueryParams.set("tab", currentTab);
    }

    navigate(`${location.pathname}?${newQueryParams.toString()}`, {
      replace: true,
    });

    // Update the internal state to reflect the current URL
    setUrlLimit(limit);
    setUrlSkip(skip);
    setCurrentPage(page); // Update the current page state
  }, [limit, skip, currentTab, navigate, location.pathname, location.search]);

  const firstRender = useFirstRender();

  const { filters, filtersComponent, setFilters } = useFilters({
    filterConfig,
    recallFunction: () => {
      // Reset skip based on the current page when filters change, avoiding reset on the first render
      setSkip(firstRender ? urlSkip : (currentPage - 1) * limit); // Use currentPage state to determine skip value
    },
    initialFilters,
  });

  useEffect(() => {
    // Recall the main function whenever the limit, skip, or filters change
    recallFunction({ limit, skip, filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, skip, filters]);

  return {
    paginationComponent,
    filtersComponent,
    limit,
    skip,
    setSkip,
    setLimit,
    filters,
    setFilters,
  };
};

export default useFiltersAndPagination;
