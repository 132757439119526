import { useAxios, useLoginContext } from "common";
import { useParams } from "react-router-dom";
import CustomerBrandView from "../CustomerBrandView";

const ViewBrand = ({ stateElement, heading }) => {
  const { brandId } = useParams();
  const { isCrmAdmin, currentUser } = useLoginContext();
  const { response } = useAxios({
    callOnLoad: {
      method: "GET",
      url: `/brands/${brandId}`,
      params: {
        populate: {
          path: "customerBrands",
          populate:
            "customer notes.user latestInvoice latestSaleOrder latestPurchaseOrder",
        },
      },
    },
  });

  const brand = response?.data;
  const customerBrands = isCrmAdmin
    ? brand?.customerBrands
    : brand?.customerBrands?.filter(
        (cb) => cb.customer?.salesRep === currentUser.id
      );

  return (
    <CustomerBrandView
      heading={heading}
      customerBrands={customerBrands}
      stateElement={stateElement}
      loading={!response}
    />
  );
};

export default ViewBrand;
