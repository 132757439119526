import { RowFlex, useAxios } from "common";
import { ViewCrmBrand } from "modules/Crm";
import { useParams } from "react-router-dom";
import { ViewBrandLink, ViewOffersLink, ViewOrdersLink } from "../LinkButtons";

const ViewCustomers = () => {
  const { brandId } = useParams();
  const { response } = useAxios({
    callOnLoad: {
      method: "GET",
      url: `/brands/${brandId}`,
    },
  });

  return (
    <ViewCrmBrand
      stateElement={
        <RowFlex>
          <ViewOrdersLink />
          <ViewBrandLink />
          <ViewOffersLink />
        </RowFlex>
      }
      heading={response?.data.name}
    />
  );
};

export default ViewCustomers;
