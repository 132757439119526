import {
  FormikSubmit,
  OverflowContainer,
  RowFlex,
  TextField,
  theme,
  useAxios,
} from "common";
import { Field, Formik } from "formik";
import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import * as yup from "yup";

const initialValues = {
  Email: "",
  CustomerName: "",
};

const validationSchema = yup.object({
  Email: yup.string().required(),
  CustomerName: yup.string().required(),
});

const CreateCustomer = ({ cancel, onComplete }) => {
  const { callAxios, loading } = useAxios({
    alertSuccess: "Customer Created",
    onComplete: (r) => {
      onComplete(r.data.id);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(data) => {
        callAxios({ method: "POST", url: "/crmCustomers", data });
      }}
    >
      <OverflowContainer>
        <RowFlex>
          <h3>Create Customer</h3>
          <MdOutlineCancel
            color={theme.colors.danger}
            cursor="pointer"
            onClick={cancel}
          />
        </RowFlex>
        <br />
        <Field
          component={TextField}
          name="CustomerName"
          label="Customer"
          required
        />
        <br />
        <Field component={TextField} name="Email" label="Email" required />
        <br />
        <FormikSubmit fit loading={loading}>
          Create
        </FormikSubmit>
      </OverflowContainer>
    </Formik>
  );
};

export default CreateCustomer;
