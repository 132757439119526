import {
  formatNumber,
  getDateWithHumanReadableTime,
  OverflowContainer,
  Table,
} from "common";

const TableView = ({ pricesheetDownloads = [], isCustomer }) => {
  return (
    <OverflowContainer>
      <Table>
        <thead>
          <tr>
            <th>Offer Date</th>
            <th>Offered By</th>
            <th>{isCustomer ? "Brand" : "Customer"}</th>
            {!isCustomer && <th>Sales Rep</th>}
            <th>Margin</th>
          </tr>
        </thead>
        <tbody>
          {pricesheetDownloads.map((offer) => {
            return (
              <tr key={offer.id}>
                <td>{getDateWithHumanReadableTime(offer.date)}</td>
                <td>{offer.user?.name}</td>
                <td>
                  {isCustomer
                    ? offer.brand?.name
                    : offer.customer?.CustomerName}
                </td>
                {!isCustomer && <td>{offer.customer?.salesRep?.name}</td>}
                <td>{formatNumber(offer.margin?.toFixed())}%</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </OverflowContainer>
  );
};

export default TableView;
