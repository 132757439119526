import { theme } from "common/context";
import Tooltip from "rc-tooltip";
import React from "react";

const StringConcat = ({ length = 20, value = "" }) => {
  if (value.length < 20) return value;
  return (
    <Tooltip
      overlay={<div style={{ color: theme.colors.white }}>{value}</div>}
      destroyTooltipOnHide
    >
      <div>{value.substring(0, length)}...</div>
    </Tooltip>
  );
};

export default StringConcat;
