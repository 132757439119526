import { OverflowContainer } from "common";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CustomerBrandCard from "./CustomerBrandCard";

const MasonryView = ({
  brands = [],
  isCustomer,
  nameKey,
  updateCustomerBrandState,
}) => {
  return (
    <OverflowContainer>
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          450: 1,
          700: 2,
          900: 3,
        }}
      >
        <Masonry gutter="1rem">
          {brands.map((cb) => {
            return (
              <CustomerBrandCard
                key={cb.id}
                nameKey={nameKey}
                customerBrand={cb}
                isCustomer={isCustomer}
                updateCustomerBrandState={updateCustomerBrandState}
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
    </OverflowContainer>
  );
};

export default MasonryView;
