import axios from "axios";
import {
  Attachments,
  Button,
  Card,
  CollapsibleHeader,
  DatePicker,
  Dropdown,
  FormikSubmit,
  FullPageLoad,
  ItemSplitter,
  Link,
  RowFlex,
  Table,
  TextField,
  TextStyledLink,
  fetchImage,
  generateLinkWithParams,
  isKanda,
  linkPlaceholders,
  routing,
  theme,
  useAlertModal,
  useAxios,
  useCrmCustomers,
  useCurrencies,
  useLoginContext,
  useModalContext,
} from "common";
import VendorBrands from "common/modules/VendorBrands";
import { Field, Form, Formik } from "formik";
import { __ } from "ramda";
import { Fragment, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { baseURL } from "../../../../src/common/axios.js";
import EmailExportModal from "./EmailExportModal";
import ExcelView from "./ExcelView";
import {
  ViewCustomersLink,
  ViewOffersLink,
  ViewOrdersLink,
} from "./LinkButtons.js";
import Notes from "./Notes";
import { formikSchema } from "./formikSchema";
import Overseas from "./overseas";
import {
  BrandKpi,
  Container,
  DetailsContainer,
  ExportActions,
  ExportContainer,
  ExportTab,
  ExportTabs,
  FormWrapperHorizontal,
} from "./styles";

const ViewBrand = () => {
  const navigate = useNavigate();
  const { setModalContent } = useModalContext();
  const { isPriceSheetsAdmin } = useLoginContext();
  const { currencyOptions } = useCurrencies();
  const [pricesheetId, setPricesheetId] = useState();
  const [onExport, setOnExport] = useState(true);
  const [showChildBrands, setShowChildBrands] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const { crmCustomers } = useCrmCustomers();
  const { brandId } = useParams();
  const [prms] = useSearchParams();
  const customer = prms.get("customer");
  const { refetch, response } = useAxios({
    clearResponse: false,
    callOnLoad: {
      method: "GET",
      url: `/brands/${brandId}`,
      params: {
        populate: JSON.stringify([
          { path: "pricesheets", populate: "childBrands parentBrand" },
          "childBrands",
          "parentBrand",
        ]),
      },
    },
  });
  const CustomDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgb(242, 242, 242);
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 5px;
    transition: background-color 200ms ease;

    &:hover {
      background-color: white;
    }
  `;
  const CustomDiv1 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(242, 242, 242);
    height: 150px;
    border-radius: 5px;
    transition: background-color 200ms ease;

    &:hover {
      background-color: white;
    }
  `;
  const { response: currentUser } = useAxios({
    clearResponse: false,
    callOnLoad: {
      method: "GET",
      url: `/auth/me`,
    },
  });

  const parentBrand = response?.data;

  const alert = useAlertModal("Please Ensure Up-front Payment");

  if (!parentBrand) return <FullPageLoad fillWidth />;

  if (parentBrand.brand)
    navigate(
      generateLinkWithParams(routing.brands.view, {
        [linkPlaceholders.brandId]: parentBrand.brand,
      })
    );

  const selectedPricesheetId = pricesheetId || parentBrand.id;
  const isParentBrand = selectedPricesheetId === parentBrand.id;

  const brandSelection = [parentBrand, ...(parentBrand.pricesheets || [])];

  const brand = brandSelection.find((b) => b.id === selectedPricesheetId);
  const image = brand.image || parentBrand.image;
  const openModal = (params, show = true, childBrand = undefined) => {
    show &&
      setModalContent(
        <ExcelView
          brand={childBrand || brand}
          params={{ customer, ...params }}
        />
      );
  };

  const updateNetherlandField = async (netherland) => {
    try {
      const updateUrl = `${baseURL}/netherland/${brand.id}`;
      await axios.put(updateUrl, { netherland: !netherland });
      // Handle any post-update logic here, if necessary
    } catch (error) {
      console.error("Failed to update netherland field:", error);
      // Handle error
    }
  };
  const openOverseasModal = (margin) => {
    setModalContent(
      <Overseas
        brand={brand}
        margin={margin}
        onUpdateNetherland={updateNetherlandField}
        customer={customer}
      />
    );
  };
  const linkParams = { [linkPlaceholders.brandId]: brand.id };
  const {
    currency: { symbol },
  } = brand;
  const hasMaxOrderDollarAmount = !!brand.maximumOrderDollarAmount;
  const amountRange = `${symbol}${numberWithCommas(
    brand.minimumOrderDollarAmount || 0
  )}${
    hasMaxOrderDollarAmount
      ? ` - ${symbol}${numberWithCommas(brand.maximumOrderDollarAmount || 0)}`
      : ""
  }`;
  const hasMaxItemAmount = !!brand.maximumOrderItems;
  const itemRange = `${brand.minimumOrderItems || 0}${
    hasMaxItemAmount ? ` - ${brand.maximumOrderItems || 0}` : ""
  }`;
  const generateLink = generateLinkWithParams(__, linkParams);
  const suggestedMargin = isKanda()
    ? Math.max(brand.suggestedMargin, 30)
    : brand.suggestedMargin;

  const margins = [
    {
      margin: brand.minimumMargin,
      show: !isKanda(),
      title: "Min. Margin",
    },
    {
      margin: suggestedMargin,
      show: true,
      title: "Suggested Margin",
    },
    {
      margin: brand.maximumMargin,
      show: !isKanda(),
      title: "Max. Margin",
    },
  ].map((margin) => {
    return (
      margin.show && (
        <CustomDiv
          key={margin.title}
          onClick={() => {
            if (
              parentBrand?.isOverseas &&
              parentBrand?.overseasShippingCost !== null
            ) {
              openOverseasModal(margin);
            }
            //   }
            // if (selectedLocation !== "")
            else {
              const open = () =>
                openModal(
                  {
                    margin: margin.margin,
                  },
                  parentBrand?.itemsHaveCostPrice
                );
              if (margin.margin < 12) {
                alert(null, open);
              } else {
                open();
              }
            }
          }}
        >
          <h3>{margin.margin || 0}%</h3>
          <p>{margin.title}</p>
        </CustomDiv>
      )
    );
  });

  return (
    <Container>
      {brandSelection.length > 1 && (
        <RowFlex>
          {brandSelection.map((b) => (
            <div key={b.id}>
              <Button
                fillWidth
                secondary={b.id !== selectedPricesheetId}
                onClick={() => setPricesheetId(b.id)}
              >
                {b.tabName || b.name}
                {b.id === parentBrand.id && " (default)"}
              </Button>
            </div>
          ))}
        </RowFlex>
      )}
      <h1>
        <RowFlex responsive>
          Brand Details{" "}
          {isPriceSheetsAdmin && (
            <Fragment>
              <Link to={generateLink(routing.brands.edit)}>(Edit)</Link>
              {isParentBrand && (
                <Link
                  to={{
                    pathname: routing.brands.add,
                    search: `?brand=${brand.id}`,
                  }}
                >
                  (Add Pricesheet)
                </Link>
              )}
            </Fragment>
          )}
          {brand.parentBrand && (
            <Link
              to={generateLinkWithParams(routing?.brands?.view, {
                [linkPlaceholders?.brandId]: brand?.parentBrand?.id,
              })}
            >
              (Go to Parent - {parentBrand?.name})
            </Link>
          )}
          {brand.isInactive ? (
            <span style={{ color: theme.colors.danger }}>[Inactive]</span>
          ) : (
            <span>[Active]</span>
          )}
          <ViewCustomersLink />
          <ViewOrdersLink />
          <ViewOffersLink />
        </RowFlex>
      </h1>
      <DetailsContainer>
        <CustomDiv>
          {image && (
            <img
              style={{ margin: "0" }}
              src={fetchImage(image)}
              alt="Brand"
              width="100px"
            />
          )}
          <h2>{brand.name}</h2>
          {isPriceSheetsAdmin && (
            <Link to={generateLink(routing.brands.changeImage)}>
              (Change Image)
            </Link>
          )}
        </CustomDiv>

        <ItemSplitter split={isKanda() ? 1 : 3}>{margins}</ItemSplitter>
        {!isKanda() && (
          <>
            <BrandKpi>
              <div style={{ display: "grid", height: "100%" }}>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Shipping Cost:
                  </span>
                  <strong>{brand.shippingCost}%</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Commission Cost:
                  </span>
                  <strong>{brand.commissionCost}%</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Other Costs:
                  </span>{" "}
                  <strong>{brand.otherCosts}%</strong>
                </p>
              </div>
            </BrandKpi>
            <BrandKpi>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  gap: "4px",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Cutoff Date:
                  </span>
                  <strong>{brand.cutoffdate}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    SKU Assortment:
                  </span>
                  <strong>{brand.skuassortment}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Min Order Amount:
                  </span>
                  <strong>{brand.minimumOrderDollarAmount}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Lead Time:
                  </span>
                  <strong>{brand.leadTime}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Special Discount:
                  </span>
                  <strong>{brand.specialDiscountNotes}</strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    width: "200px",
                    margin: "auto",
                  }}
                >
                  <span style={{ display: "inline-block", width: "120px" }}>
                    Notes:
                  </span>{" "}
                  <strong>{brand.notes}</strong>
                </p>
              </div>
            </BrandKpi>
          </>
        )}

        <CustomDiv1>
          <h3>{amountRange}</h3>
          <p>{`Min.${hasMaxOrderDollarAmount ? " - Max." : ""} Amount`}</p>
        </CustomDiv1>
        <CustomDiv1>
          <h3>{itemRange}</h3>
          <p>{`Min.${hasMaxItemAmount ? " - Max." : ""} Number of Items`}</p>
        </CustomDiv1>
      </DetailsContainer>
      <Attachments
        url={`/brands/${brand.id}`}
        attachments={brand.attachments}
        onComplete={refetch}
      />
      <Notes brand={brand} />
      {currentUser?.data?.priceSheetsRole === "Admin" && (
        <VendorBrands id={brand.id} onComplete={refetch} />
      )}
      <Fragment width={"auto"}></Fragment>
      <ExportContainer>
        <ExportTabs>
          <ExportTab
            isActive={onExport}
            onClick={() => {
              setOnExport(true);
            }}
          >
            Export
          </ExportTab>
          {!isKanda() && (
            <ExportTab
              isActive={!onExport}
              onClick={() => {
                setOnExport(false);
              }}
            >
              Email
            </ExportTab>
          )}
        </ExportTabs>
        <ExportActions>
          {onExport ? (
            <Formik
              enableReinitialize
              {...formikSchema(
                brand,
                currentUser?.data?.priceSheetsRole,
                currentUser?.data?.email,
                customer
              )}
              onSubmit={(params) => {
                const open = () => openModal(params);
                if (params.margin < 12) return alert(null, open);

                return open();
              }}
            >
              {({ handleChange, values }) => {
                return (
                  <>
                    <FormWrapperHorizontal>
                      <Form>
                        <Field
                          name="margin"
                          label={`Profit Margin %${
                            !brand.itemsHaveCostPrice
                              ? " (Items do not have Cost Price)"
                              : ""
                          }`}
                          disabled={!brand.itemsHaveCostPrice || values.isRaw}
                          required
                          component={TextField}
                          type="number"
                          onFocus={() => {
                            handleChange({
                              target: {
                                name: "MSRPDiscount",
                                value: undefined,
                              },
                            });
                          }}
                        />
                        <Field
                          name="MSRPDiscount"
                          label={`Discount %${
                            !brand.itemsHaveMSRP
                              ? " (Items do not have MSRP)"
                              : ""
                          }`}
                          disabled={
                            !brand.itemsHaveMSRP || values.isRaw || isKanda()
                          }
                          component={TextField}
                          type="number"
                          onFocus={() => {
                            handleChange({
                              target: { name: "margin", value: undefined },
                            });
                          }}
                        />
                        <Field
                          name="fromCurrency"
                          label="From Currency"
                          component={Dropdown}
                          options={currencyOptions}
                          disabled
                        />
                        <Field
                          name="toCurrency"
                          label="To Currency"
                          component={Dropdown}
                          options={currencyOptions}
                        />
                        <Field
                          name="customer"
                          label="Customer"
                          component={Dropdown}
                          options={crmCustomers}
                        />
                        <Field
                          name="followUpDate"
                          label="Follow Up Date"
                          component={DatePicker}
                        />
                      </Form>
                      <FormikSubmit>Export</FormikSubmit>
                    </FormWrapperHorizontal>
                    <br />
                    <br />
                  </>
                );
              }}
            </Formik>
          ) : (
            <EmailExportModal
              brand={brand}
              customer={customer}
              crmCustomers={crmCustomers}
            />
          )}
        </ExportActions>
      </ExportContainer>
      {brand.childBrands.length > 0 && (
        <CollapsibleHeader
          header="Child Brands"
          show={showChildBrands}
          setShow={setShowChildBrands}
        />
      )}
      {showChildBrands && (
        <Card>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                {!isKanda() && <th>Min. Margin</th>}
                <th>Suggested Margin</th>
                {!isKanda() && <th>Max. Margin</th>}
              </tr>
            </thead>
            <tbody>
              {brand.childBrands.map((childBrand) => {
                const childSuggestedMargin = isKanda()
                  ? Math.max(childBrand.suggestedMargin, 30)
                  : childBrand.suggestedMargin;
                const childMargins = [
                  {
                    margin: childBrand.minimumMargin,
                    show: !isKanda(),
                    title: "Min. Margin",
                  },
                  {
                    margin: childSuggestedMargin,
                    show: true,
                    title: "Suggested Margin",
                  },
                  {
                    margin: childBrand.maximumMargin,
                    show: !isKanda(),
                    title: "Max. Margin",
                  },
                ].map((margin) => {
                  return (
                    margin.show && (
                      <BrandKpi
                        key={margin.title}
                        onClick={() => {
                          const open = () =>
                            openModal(
                              {
                                margin: margin.margin,
                              },
                              childBrand.itemsHaveCostPrice,
                              childBrand
                            );
                          if (margin.margin < 12) {
                            alert(null, open);
                          } else {
                            open();
                          }
                        }}
                      >
                        <h3>{margin.margin || 0}%</h3>
                        <p>{margin.title}</p>
                      </BrandKpi>
                    )
                  );
                });
                return (
                  <tr key={childBrand.id}>
                    <td>
                      <Link
                        to={generateLinkWithParams(routing.brands.view, {
                          [linkPlaceholders.brandId]: childBrand.id,
                        })}
                      >
                        {childBrand.name}
                      </Link>
                    </td>
                    {childMargins}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>
      )}
      <CollapsibleHeader
        header="More Options"
        show={showMoreOptions}
        setShow={setShowMoreOptions}
      />
      {showMoreOptions && (
        <div>
          <TextStyledLink
            onClick={() => {
              openModal({
                isRaw: true,
              });
            }}
          >
            Export Original Vendor Cost
          </TextStyledLink>
        </div>
      )}
    </Container>
  );
};

export default ViewBrand;
export { default as ViewBrandCustomers } from "./ViewCustomers";
export { default as ViewBrandOrders } from "./ViewOrders";
export { default as ViewBrandOffers } from "./ViewOffers";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
