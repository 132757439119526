import {
  Button,
  Dropdown,
  sortAndFormatOptions,
  Spinner,
  StringConcat,
  TextStyledLink,
  useAxios,
  useModalContext,
} from "common";
import { useState } from "react";

const BrandRow = ({ brand, initialMatches = [], crmBrands = [], refetch }) => {
  const [ids, setIds] = useState(initialMatches);
  const { setModalContent, closeModal } = useModalContext();
  const { callAxios, loading } = useAxios({
    alertSuccess: "CRM Brands Linked!",
    onComplete: () => {
      refetch();
      closeModal();
    },
  });

  return (
    <tr>
      <td>
        <StringConcat value={brand.name} />
      </td>
      <td>
        <Dropdown
          fillWidth
          value={ids}
          isMulti
          options={sortAndFormatOptions({
            list: crmBrands,
            labelKey: "name",
            valueKey: "id",
          })}
          onChange={(v) => setIds(v)}
        />
      </td>
      <td>
        {loading ? (
          <Spinner inline />
        ) : (
          ids.length > 0 && (
            <TextStyledLink
              onClick={() => {
                setModalContent(
                  <div>
                    <h3>
                      Brand {brand.name} Will be linked to: {ids}
                    </h3>
                    <Button
                      loading={loading}
                      danger
                      onClick={() => {
                        callAxios({
                          method: "PUT",
                          url: `/brands/${brand.id}`,
                          data: { crmIds: ids },
                        });
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                );
              }}
            >
              Update
            </TextStyledLink>
          )
        )}
      </td>
    </tr>
  );
};

export default BrandRow;
