import validationSchema from "../validationSchema";

const formikSchema = {
  initialValues: {
    name: "",
    email: "",
    crmRole: "",
    priceSheetsRole: "",
    sourcingRole: "",
    brandListRole: "",
    admin: false,
  },
  validationSchema,
};

export default formikSchema;
