import {
  OverflowContainer,
  RowFlex,
  Spinner,
  StringConcat,
  TextField,
  useAxios,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableView from "./TableView";
import { orderBy } from "lodash";

const OrderView = ({ stateElement }) => {
  const { crmCustomerId, brandId } = useParams();
  const [search, setSearch] = useState("");
  const key = crmCustomerId ? "CustomerName" : "name";

  const { response, callAxios } = useAxios();

  const saleOrders = useMemo(() => {
    if (!response) return [];

    const sos = orderBy(
      response.data.saleOrders,
      (so) => new Date(so.OrderDate),
      "desc"
    );

    if (!search) return sos;

    return sos.filter((so) => {
      const searchMe = [
        so.OrderNumber || "",
        so.Id || "",
        ...(crmCustomerId
          ? so.Brands?.map((b) => b?.name || "") || []
          : [so.CustomerName]),
        so.SalesRepresentative || "",
      ]
        .filter(Boolean)
        .join("|")
        .trim()
        .toLowerCase();
      return searchMe.includes(search.toLowerCase());
    });
  }, [search, response, crmCustomerId]);

  useEffect(() => {
    if (!crmCustomerId && !brandId) return;

    callAxios({
      method: "GET",
      url: `/${brandId ? "brands" : "crmCustomers"}/${
        brandId || crmCustomerId
      }`,
      params: {
        select: `saleOrders ${key}`,
        populate: [
          {
            path: "saleOrders",
            ...(crmCustomerId
              ? {
                  populate: [{ path: "Brands", select: "name" }],
                }
              : {}),
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmCustomerId, brandId, key]);

  const item = response?.data;

  return (
    <OverflowContainer titleWithBody>
      <RowFlex responsive extend>
        {item ? (
          <div>
            <h1>
              <StringConcat value={item?.[key]} />
            </h1>
            <h4>Sale Orders</h4>
          </div>
        ) : (
          <Spinner inline />
        )}
        <RowFlex responsive>
          <TextField
            label="Search SOs"
            value={search}
            onChange={(e) => setSearch(e)}
            placeholder={`ID / # / ${
              crmCustomerId ? "Brand" : "Customer"
            } / Sales Rep`}
          />
          {stateElement}
        </RowFlex>
      </RowFlex>
      <TableView saleOrders={saleOrders} isCustomer={!!crmCustomerId} />
    </OverflowContainer>
  );
};

export default OrderView;
