import { Card, CollapsibleHeader, RowFlex, StringConcat, theme } from "common";
import { lighten } from "polished";
import { useState } from "react";
import {
  AgingView,
  ChangeFollowUpDate,
  InvoiceView,
  Notes,
  PoView,
  Reject,
  SoView,
} from "../common";

const CustomerBrandCard = ({
  customerBrand,
  nameKey,
  updateCustomerBrandState,
}) => {
  const { latestSaleOrder, latestInvoice, latestPurchaseOrder } = customerBrand;
  const [showNotes, setShowNotes] = useState(false);

  const props = { brand: customerBrand, updateCustomerBrandState };

  const name = customerBrand[nameKey];
  return (
    <Card
      key={customerBrand.id}
      color={lighten(
        0.2,
        theme.colors.crmCustomerBrandStatuses[customerBrand.status]
      )}
      style={{ maxHeight: "500px", overflowY: "auto" }}
    >
      <RowFlex column>
        <RowFlex responsive extend>
          <h2>
            <StringConcat value={name} />
          </h2>
          <div>
            <Reject {...props} name={name} />
          </div>
        </RowFlex>
        <SoView so={latestSaleOrder} />
        <PoView po={latestPurchaseOrder} />
        <InvoiceView invoice={latestInvoice} />
        <ChangeFollowUpDate {...props} label="Follow Up Date" />
        {customerBrand.aging && <h4>Aging:</h4>}
        <AgingView {...props} />
        <CollapsibleHeader
          header="Notes"
          show={showNotes}
          setShow={setShowNotes}
        />
        {showNotes && <Notes {...props} />}
      </RowFlex>
    </Card>
  );
};

export default CustomerBrandCard;
