import {
  Button,
  crmCustomerBrandStatuses,
  crmCustomerBrandStatusOptions,
  RowFlex,
  Spinner,
  theme,
  useAxios,
} from "common";
import { orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Filters from "./Filters";
import MasonryView from "./MasonryView";
import { Container } from "./styles";
import TableView from "./TableView";

const CustomerBrandView = ({ heading, isCustomer, stateElement }) => {
  const { crmCustomerId, brandId } = useParams();
  const [isTable, setIsTable] = useState(true);
  const [customerBrands, setCustomerBrands] = useState([]);
  const [filterId, setFilterId] = useState("");
  const [status, setStatus] = useState(
    crmCustomerBrandStatuses.NOT_OFFERED_YET
  );
  const nameKey = isCustomer ? "brandName" : "customerName";

  const { callAxios: getCustomerBrands, loading } = useAxios({
    onComplete: (r) => {
      setCustomerBrands(r.data);
    },
  });

  useEffect(() => {
    getCustomerBrands({
      method: "GET",
      url: "/crmCustomerBrands",
      params: {
        filters: {
          ...(isCustomer ? { customer: crmCustomerId } : { brand: brandId }),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, crmCustomerId, isCustomer]);

  const updateCustomerBrandState = useCallback(
    (cb) =>
      setCustomerBrands((prev) => prev.map((p) => (p.id === cb.id ? cb : p))),
    []
  );

  const getIdFilter = useCallback(
    ({ brandId, customerId }) =>
      !filterId ||
      (isCustomer ? brandId === filterId : customerId === filterId),
    [filterId, isCustomer]
  );

  const filteredBrands = useMemo(
    () =>
      orderBy(
        customerBrands.filter((cb) => {
          const isStatus = !status || status === cb.status;

          return getIdFilter(cb) && isStatus;
        }),
        (v) => v[nameKey]?.toLowerCase()
      ),
    [customerBrands, getIdFilter, status, nameKey]
  );

  const View = isTable ? TableView : MasonryView;

  return (
    <Container>
      <RowFlex responsive extend>
        <h1>{heading}</h1>
        <Filters
          isCustomer={isCustomer}
          stateElement={stateElement}
          filterId={filterId}
          setFilterId={setFilterId}
          isTable={isTable}
          setIsTable={setIsTable}
        />
      </RowFlex>
      <RowFlex>
        {crmCustomerBrandStatusOptions.map(({ value }) => {
          const statusBrandCount = customerBrands.filter(
            (c) => c.status === value && getIdFilter(c)
          ).length;
          const statusCount = loading ? " ..." : ` (${statusBrandCount})`;
          const selected = value === status;
          return (
            <Button
              fit
              key={value}
              secondary
              onClick={() => setStatus(value)}
              style={{
                border:
                  selected &&
                  `5px solid ${theme.colors.crmCustomerBrandStatuses[value]}`,
              }}
            >
              {value}
              {statusCount}
            </Button>
          );
        })}
      </RowFlex>
      {loading ? (
        <Spinner inline />
      ) : (
        <View
          filteredStatus={status}
          brands={filteredBrands}
          isCustomer={isCustomer}
          nameKey={nameKey}
          updateCustomerBrandState={updateCustomerBrandState}
        />
      )}
    </Container>
  );
};

export default CustomerBrandView;
export * from "./common";
