import {
  OverflowContainer,
  RowFlex,
  Spinner,
  StringConcat,
  TextField,
  useAxios,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableView from "./TableView";
import { orderBy } from "lodash";

const OfferView = ({ stateElement }) => {
  const { crmCustomerId, brandId } = useParams();
  const [search, setSearch] = useState("");
  const key = crmCustomerId ? "CustomerName" : "name";

  const { response, callAxios } = useAxios();

  const pricesheetDownloads = useMemo(() => {
    if (!response) return [];

    const pDownloads = orderBy(
      response.data.pricesheetDownloads,
      (offer) => new Date(offer.date),
      "desc"
    );

    if (!search) return pDownloads;

    return pDownloads.filter((offer) => {
      const searchMe = [
        offer.brand?.name || "",
        offer.customer?.CustomerName || "",
        offer.user?.name || "",
        offer.customer?.salesRep?.name || "",
      ]
        .filter(Boolean)
        .join("|")
        .trim()
        .toLowerCase();
      return searchMe.includes(search.toLowerCase());
    });
  }, [search, response]);

  useEffect(() => {
    if (!crmCustomerId && !brandId) return;

    callAxios({
      method: "GET",
      url: `/${brandId ? "brands" : "crmCustomers"}/${
        brandId || crmCustomerId
      }`,
      params: {
        select: `pricesheetDownloads ${key}`,
        populate: [
          {
            path: "pricesheetDownloads",
            populate: [
              "user",
              {
                path: crmCustomerId ? "brand" : "customer",
                select: `${crmCustomerId ? "name" : "CustomerName"}${
                  !crmCustomerId ? " salesRep" : ""
                }`,
                ...(!crmCustomerId ? { populate: "salesRep" } : {}),
              },
            ],
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crmCustomerId, brandId, key]);

  const item = response?.data;

  return (
    <OverflowContainer titleWithBody>
      <RowFlex responsive extend>
        {item ? (
          <div>
            <h1>
              <StringConcat value={item?.[key]} />
            </h1>
            <h4>Offers</h4>
          </div>
        ) : (
          <Spinner inline />
        )}
        <RowFlex responsive>
          <TextField
            label="Search SOs"
            value={search}
            onChange={(e) => setSearch(e)}
            placeholder={`ID / ${
              crmCustomerId ? "Brand" : "Customer"
            } / Sales Rep`}
          />
          {stateElement}
        </RowFlex>
      </RowFlex>
      <TableView
        pricesheetDownloads={pricesheetDownloads}
        isCustomer={!!crmCustomerId}
      />
    </OverflowContainer>
  );
};

export default OfferView;
