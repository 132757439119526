import styled, { css } from "styled-components";

const OverflowContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  ${({ titleWithBody }) =>
    titleWithBody &&
    css`
      display: grid;
      grid-template-rows: auto 1fr;
      grid-gap: 1rem;
    `}
`;

export default OverflowContainer;
