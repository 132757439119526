import {
  crmCustomerBrandStatuses,
  getDateWithHumanReadableTime,
  TableView,
  useBrands,
  useCrmCustomers,
  useLoginContext,
  useModalContext,
  useUsers,
} from "common";
import { GrNotes } from "react-icons/gr";
import { InvoiceView, Notes, PoView, SoView } from "../CustomerBrandView";
import { TableNoteView } from "../common";

const AwaitingReorderList = () => {
  const { brands } = useBrands();
  const { isCrmAdmin } = useLoginContext();
  const { crmCustomers } = useCrmCustomers();
  const { setModalContent } = useModalContext();
  const users = useUsers();

  return (
    <TableView
      url="/crmCustomerBrandList"
      tableConfig={[
        {
          name: "customer",
          header: "Customer",
        },
        {
          name: "brand",
          header: "Brand",
        },
        {
          name: "latestSaleOrder",
          header: "Latest SO",
        },
        {
          name: "latestPurchaseOrder",
          header: "Latest PO",
        },
        {
          name: "latestInvoice",
          header: "Latest Invoice",
        },
        {
          name: "latestSaleOrderDate",
          header: "Latest SO Shipped",
          isHumanReadableDate: true,
        },
        {
          name: "latestNote",
          header: "Latest Note",
        },
        {
          name: "notes",
          header: "",
        },
      ]}
      defaultFilters={{
        status: crmCustomerBrandStatuses.AWAITING_REORDER,
      }}
      defaultParams={{
        sort: "-latestSaleOrderDate",
        populate: JSON.stringify([
          { path: "customer", select: "CustomerName" },
          { path: "brand", select: "name" },
          "latestSaleOrder",
          "latestPurchaseOrder",
          "latestInvoice",
          { path: "notes", populate: "user" },
        ]),
      }}
      header="Awaiting Reorders"
      filterConfig={[
        {
          name: "brand",
          label: "Brand",
          type: "dropdown",
          options: brands,
        },
        {
          name: "customer",
          label: "Customer",
          type: "dropdown",
          options: crmCustomers,
        },
        ...(isCrmAdmin
          ? [
              {
                name: "salesRep",
                label: "Sales Rep",
                type: "dropdown",
                options: users,
              },
            ]
          : []),
        {
          name: "followUpDate",
          label: "Follow Up Date",
          type: "daterange",
        },
      ]}
      shapeData={(d, reloadTable) =>
        d.data.data.map((cust) => {
          return {
            ...cust,
            customer: cust.customer?.CustomerName,
            brand: cust.brand?.name,
            latestSaleOrder: <SoView so={cust.latestSaleOrder} inline />,
            latestPurchaseOrder: (
              <PoView po={cust.latestPurchaseOrder} inline />
            ),
            latestInvoice: <InvoiceView invoice={cust.latestInvoice} inline />,
            followUpDate: getDateWithHumanReadableTime(cust.followUpDate),
            latestNote: <TableNoteView notes={cust.notes} />,
            notes: (
              <GrNotes
                cursor="pointer"
                onClick={() =>
                  setModalContent(
                    <div
                      style={{
                        width: "400px",
                        height: "500px",
                        overflow: "auto",
                      }}
                    >
                      <Notes
                        brand={cust}
                        updateCustomerBrandState={reloadTable}
                      />
                    </div>
                  )
                }
              />
            ),
          };
        })
      }
    />
  );
};

export default AwaitingReorderList;
