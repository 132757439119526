import { Dropdown, RowFlex, theme, useBrands, useCrmCustomers } from "common";
import { CiViewTable } from "react-icons/ci";
import { MdViewKanban } from "react-icons/md";

const Filters = ({
  isCustomer,
  stateElement,
  filterId,
  setFilterId,
  isTable,
  setIsTable,
}) => {
  const { brands, loading: bLoading } = useBrands();
  const { crmCustomers, loading: cLoading } = useCrmCustomers();

  const Toggle = isTable ? MdViewKanban : CiViewTable;

  return (
    <RowFlex responsive>
      <Dropdown
        width="200px"
        options={isCustomer ? brands : crmCustomers}
        label={isCustomer ? "Brand" : "Customer"}
        value={filterId}
        onChange={(v) => setFilterId(v)}
        isClearable
        disabled={isCustomer ? cLoading : bLoading}
      />
      {stateElement}
      <RowFlex>
        <Toggle
          fill={theme.colors.primary}
          cursor="pointer"
          size="2rem"
          onClick={() => setIsTable((p) => !p)}
        />
      </RowFlex>
    </RowFlex>
  );
};

export default Filters;
