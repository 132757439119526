import { isMissing } from "common";
import * as yup from "yup";

const validationSchema = yup.object({
  date: yup.date().required(isMissing("Date")),
  note: yup.string().nullable(),
  amount: yup.number().required(isMissing("Amount")),
});

export default validationSchema;
