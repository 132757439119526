import {
  Button,
  Column,
  downloadFile,
  Dropdown,
  FileUpload,
  FormikSubmit,
  FormWrapper,
  generateLinkWithParams,
  ImageUpload,
  ItemSplitter,
  Link,
  linkPlaceholders,
  PageContainer,
  routing,
  TextField,
  useAxios,
  useBrands,
  useCategories,
  useCurrencies,
  useSuggestBrandListModal,
} from "common";
import * as Yup from "yup";
import { useToast } from "common/context/Toast";
import { Field, Formik, getIn } from "formik";
import { __, clone, dissoc } from "ramda";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "./styles.js";


const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  category: Yup.string().required("Category is required"),
  suggestedMargin: Yup.number().required("Suggested Margin is required"),
  minimumMargin: Yup.number().nullable().min(0).max(99.9999999).required("Minimum Margin is required"),
  maximumMargin: Yup.number().nullable().min(0).max(99.9999999).required("Maximum Margin is required"),
  shippingCost: Yup.number().nullable().required("Shipping Cost is required"),
  commissionCost: Yup.number().nullable().required("Commission Cost is required"),
  minimumOrderDollarAmount: Yup.number().nullable().required("Minimum Order Amount is required"),
  skuassortment: Yup.string().nullable().required("SKU Assortment is required"),
  soldByCaseOnly: Yup.boolean().required("Sold By Case Only is required").nullable(),
  fobPoint: Yup.string().nullable().required("FOB Point is required"),
  leadTime: Yup.string().nullable().required("Lead Time is required"),
  cutoffdate: Yup.number().nullable().min(0, "Cutoff date must be between 0 and 30")
                       .max(30, "Cutoff date must be between 0 and 30")
                       .notRequired(),
 
});



const BrandForm = ({ isEdit, formikSchema, parentBrand }) => {
  const [val, setVal] = useState({
    CutoffDate:false,
  })
  function handleOnChange(e, form) {
  
    
    if (e < 0 || e > 30) {
      setVal({ ...val, CutoffDate: true });
    } else {
      setVal({ ...val, CutoffDate: false });
    }
    
   
  }

  const { onError, alertSuccess } = useToast();
  const { brandId } = useParams();
  const initialIsOverseasValue = formikSchema.initialValues.isOverseas;

  const [overseasChecked, setOverseasChecked] = useState(
    initialIsOverseasValue
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const goBack = (id) =>
    navigate(
      !id
        ? routing.brands.root
        : generateLinkWithParams(routing.brands.view, {
            [linkPlaceholders.brandId]: id,
          })
    );
  const { callAxios } = useAxios();
  const { callAxios: getSampleFile, loading: loadingSampleFile } = useAxios({
    onComplete: ({ data: sampleFile }) => {
      downloadFile(sampleFile);
    },
  });

  const { currencies, currencyOptions } = useCurrencies();

  const { categories: categoryOptions } = useCategories();

  const { brands: brandOptions } = useBrands();

  const getCurrencyObject = (code) => currencies.find((c) => c.code === code);

  const handleSubmitEdit = async (values) => {
    return callAxios({
      method: "PUT",
      url: `/brands/${values.id}`,
      data: dissoc("id", {
        ...values,
        currency: getCurrencyObject(values.currency),
        isOverseas: overseasChecked,
      }),
    });
  };

  const suggestCreateBrandList = useSuggestBrandListModal();

  const handleSubmit = async (oldValues) => {
    const values = {
      ...clone(oldValues),
      currency: getCurrencyObject(oldValues.currency),
    };
    if (values.image) {
      const imageFile = new File(
        [values.image],
        `${values.name}_image`.replace(/ /g, "_").toLowerCase() +
          values.image.type.replace("image/", ".")
      );
      const data = new FormData();
      data.append("file", imageFile);
      const res = await callAxios({
        method: "POST",
        url: "/files/upload",
        data,
        headers: {
          "Content-Type": "blob",
        },
      });
      values.image = res.data.filename;
    }
    return callAxios({
      method: "POST",
      url: "/brands",
      data: dissoc("lineItemsFile", values),
    });
  };

  const handleFileUpload = async (file, fileBrandId) => {
    if (file) {
      const data = new FormData();
      data.append("itemsExcel", file);
      await callAxios({
        method: "POST",
        url: `/brands/${fileBrandId}/upload`,
        data,
        headers: {
          "Content-Type": "blob",
        },
      });
    }
  };

  const generateLink = generateLinkWithParams(__, {
    [linkPlaceholders.brandId]: brandId,
  });

  return (
    <PageContainer>
      <h1>
        {parentBrand
          ? `Add Pricesheet to ${parentBrand}`
          : isEdit
          ? "Update Brand"
          : "Add Brand"}
      </h1>
      <Formik
        enableReinitialize
        {...formikSchema}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={false}
      
        onSubmit={(values) => {
          setLoading(true);
          const submitFunction = isEdit ? handleSubmitEdit : handleSubmit;
          submitFunction(values)
            .then(async (res) => {
              const id = getIn(res, "data.id");
              const newBrand = res.data;
              setLoading(false);
              if (values.lineItemsFile) {
                setLoading(true);
                handleFileUpload(values.lineItemsFile, id)
                  .then(async () => {
                    alertSuccess(
                      `Brand Successfully ${isEdit ? "Update" : "Create"}d!`
                    );
                    goBack(id);
                  })
                  .catch(() => {
                    goBack(id);
                  })
                  .finally(() => setLoading(false));
              } else {
                alertSuccess(
                  `Brand Successfully ${isEdit ? "Update" : "Create"}d!`
                );
                if (newBrand?.category) {
                  const { data } = await callAxios({
                    method: "POST",
                    url: "/brandList/exists",
                    data: { name: newBrand.name },
                  });
                  if (!data) suggestCreateBrandList({ data: newBrand });
                }
                goBack(id);
              }
            })
            .catch((err) => {
              onError(err);
              setLoading(false);
            });
        }}
      >
        <FormWrapper>
          <Form style={{ display: "flex" }}>
            <Column style={{ width: "100%" }}>
              <ItemSplitter>
                <Field
                  name="category"
                  component={Dropdown}
                  label="Category"
                  options={categoryOptions}
                  required
                />
                {!parentBrand && (
                  <Field
                    name="parentBrand"
                    component={Dropdown}
                    label="Parent Brand"
                    options={brandOptions}
                  />
                )}
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="name"
                  component={TextField}
                  label={parentBrand ? "Pricesheet Name" : "Name"}
                  required
                  fillWidth
                  allBorders
                />
                <Field
                  name="tabName"
                  component={TextField}
                  label={"Tab Name"}
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Field
                  name="currency"
                  component={Dropdown}
                  label="Currency"
                  options={currencyOptions}
                  required
                  style={{ flex: 1 }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "15px",
                    alignItems: "flex-start",
                  }}
                >
                  <label
                    htmlFor="overseas"
                    style={{
                      marginBottom: "5px", marginLeft:"100px",
                    }}
                  >
                    <span style={{ marginLeft: "235px" }}> Overseas </span>
                  </label>
                 <div style={{marginLeft:"100px"}}>

                 <Field
                    name="isOverseas"
                    component={({ field, form }) => (
                      <input
                        type="checkbox"
                        id="isOverseas"
                        checked={field.value}
                        onChange={(e) => {
                          form.setFieldValue(field.name, e.target.checked);
                          setOverseasChecked(e.target.checked);
                        }}
                        style={{
                          marginLeft: "265px",
                          marginTop: "6px",
                          transform: "scale(1.2)",
                        }}
                      />
                    )}
                  />
                 </div>
                 
                </div>
               
            
            <div style={{marginLeft:"140px", width:"100%", position:"relative"}}>
            <Field
              name="cutoffdate"
              component={TextField}
              label="Cutoff Date (Please Enter number between 0 to 30)"
              type="number"             
              fillWidth
              allBorders
              onChange={(e, form) => handleOnChange(e, form)}
              />
          
          {val.CutoffDate && <p style={{position:"absolute",bottom:"-15px" , left:"0", color:"red", fontSize:"13px"}}>Number should between 0 & 30 </p>}
            </div>
            
              </ItemSplitter>
                   
              <Field
                name="suggestedMargin"
                component={TextField}
                label="Suggested Margin %"
                type="number"
                required
                fillWidth
                allBorders
              />
              <ItemSplitter>
                <Field
                  name="minimumMargin"
                  component={TextField}
                  label="Min. Margin %"
                  type="number"
                  required
                  fillWidth
                  allBorders
                />

                <Field
                  name="maximumMargin"
                  component={TextField}
                  label="Max. Margin %"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>
              {overseasChecked ? (
                <ItemSplitter>
                  <Field
                    name="shippingCost"
                    component={TextField}
                    label="Shipping Cost %"
                    type="number"
                    fillWidth
                    allBorders
                    required
                  />
                  <Field
                    name="overseasShippingCost"
                    component={TextField}
                    label="Overseas Shipping Cost %"
                    type="number"
                    fillWidth
                    allBorders
                  />
                </ItemSplitter>
              ) : (
                <Field
                  name="shippingCost"
                  component={TextField}
                  label="Shipping Cost %"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
              )}
              <Field
                name="commissionCost"
                component={TextField}
                label="Commission Cost %"
                type="number"
                fillWidth
                allBorders
                required
              />

              <Field
                name="otherCosts"
                component={TextField}
                label="Other Costs %"
                type="number"
                fillWidth
                allBorders
              />
              <ItemSplitter>
                <Field
                  name="minimumOrderDollarAmount"
                  component={TextField}
                  label="Min Order Amount"
                  type="number"
                  fillWidth
                  allBorders
                  required
                />
                <Field
                  name="skuassortment"
                  component={TextField}
                  label="SKU Assortment"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>
              
                <Field
                  name="maximumOrderDollarAmount"
                  component={TextField}
                  label="Max Order Amount"
                  type="number"
                  fillWidth
                  allBorders
                />
                {/* <Field
                  name="maximumOrderItems"
                  component={TextField}
                  label="Max Order Items"
                  type="number"
                  fillWidth
                  allBorders
                /> */}
           
              <Field
                name="soldByCaseOnly"
                component={Dropdown}
                label="Sold By Case Only"
                options={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                required
              />
              <ItemSplitter>
                <Field
                  name="fobPoint"
                  component={TextField}
                  label="Fob Point"
                  fillWidth
                  allBorders
                  required
                />
                <Field
                  name="leadTime"
                  component={TextField}
                  label="Lead Time"
                  fillWidth
                  allBorders
                  required
                />
              </ItemSplitter>
              <ItemSplitter>
                <Field
                  name="specialDiscountNotes"
                  component={TextField}
                  label="Special Discount Notes"
                  fillWidth
                  allBorders
                />
                <Field
                  name="notes"
                  component={TextField}
                  label="Notes"
                  fillWidth
                  allBorders
                />
              </ItemSplitter>
              <ItemSplitter
                style={{ display: "flex", justifyContent: "end" }}
                autoWidth
              >
                <FormikSubmit loading={loading}>
                  {isEdit ? "Update" : "Add"}
                </FormikSubmit>
                <Button secondary onClick={() => goBack(brandId)}>
                  Cancel
                </Button>
              </ItemSplitter>
            </Column>
            {isEdit ? (
              <Column style={{ fontSize: "0.75rem" }}>
                <div>
                  <Link to={generateLink(routing.brands.changeImage)}>
                    <Button secondary>Change Image</Button>
                  </Link>
                </div>
                <div>
                  <Link to={generateLink(routing.brands.changeFile)}>
                    <Button secondary>Upload Items</Button>
                  </Link>
                </div>
                <div>
                  <Link to={generateLink(routing.brands.attachments)}>
                    <Button secondary>View / Edit Attachments</Button>
                  </Link>
                </div>
              </Column>
            ) : (
              <Column>
                <Field name="image" component={ImageUpload} label="Image" />
                <h3>Brand Items</h3>
                <ItemSplitter>
                  <Field name="lineItemsFile" component={FileUpload} />
                  <Button
                    loading={loadingSampleFile}
                    secondary
                    onClick={() => {
                      getSampleFile({
                        method: "GET",
                        url: "/brands/downloadSampleExcel",
                        responseType: "blob",
                      });
                    }}
                  >
                    Download Sample
                  </Button>
                </ItemSplitter>
              </Column>
            )}
          </Form>
        </FormWrapper>
      </Formik>
    </PageContainer>
  );
};

export default BrandForm;
