import { linkPlaceholders, routing, TableView, useCrmCustomers } from "common";
import { InvoiceView, PoView, SoView } from "../CustomerBrandView";

const BrandList = () => {
  const { crmCustomers } = useCrmCustomers();
  return (
    <TableView
      url="/brands"
      tableConfig={[
        {
          name: "name",
          header: "Brand",
        },
        {
          name: "customerLength",
          header: "Customers",
        },
        {
          name: "potentialCustomerLength",
          header: "Potential Customers",
        },
        {
          name: "latestSaleOrder",
          header: "Latest SO",
        },
        {
          name: "latestPurchaseOrder",
          header: "Latest PO",
        },
        {
          name: "latestInvoice",
          header: "Latest Invoice",
        },
      ]}
      to={routing.crm.viewBrand}
      linkParam={linkPlaceholders.brandId}
      header="Brands"
      defaultParams={{
        populate: "latestSaleOrder latestPurchaseOrder latestInvoice",
      }}
      filterConfig={[
        { name: "name", label: "Search", type: "input" },
        {
          name: "customers",
          label: "Customer",
          type: "dropdown",
          options: crmCustomers,
        },
        {
          name: "potentialCustomers",
          label: "Potential Customer",
          type: "dropdown",
          options: crmCustomers,
        },
      ]}
      shapeData={(d) => {
        return d.data.data.map((brand) => ({
          ...brand,
          customerLength: brand.customers.length,
          potentialCustomerLength: brand.potentialCustomers.length,
          latestSaleOrder: <SoView so={brand.latestSaleOrder} inline />,
          latestPurchaseOrder: <PoView po={brand.latestPurchaseOrder} inline />,
          latestInvoice: <InvoiceView invoice={brand.latestInvoice} inline />,
        }));
      }}
    />
  );
};

export default BrandList;
