import { ChevronDown, ChevronUp } from "@carbon/icons-react";
import { useFormikContext } from "formik";
import React, { useState, useRef } from "react";
import Spinner from "../Spinner";
import { ButtonStyle, ExportButtonContainer, ButtonOptions } from "./styles";

const Button = ({
  children,
  className,
  disabled,
  loading,
  onClick,
  onBlur,
  secondary,
  style,
  type = "button",
  fit,
  danger,
}) => {
  return (
    <ButtonStyle
      className={className}
      disabled={disabled || loading}
      onClick={onClick}
      format={secondary ? "secondary" : danger ? "danger" : "standard"}
      style={style}
      type={type}
      onBlur={onBlur}
      fit={fit}
      danger={danger}
    >
      {loading ? <Spinner color="white" inline /> : children}
    </ButtonStyle>
  );
};

const FormikSubmit = ({
  children,
  loading,
  disabled,
  useDirty = false,
  fit,
  danger,
}) => {
  const { submitForm, dirty } = useFormikContext();
  return (
    <Button
      type="button"
      onClick={submitForm}
      disabled={useDirty ? !dirty : disabled}
      loading={loading}
      fit={fit}
      danger={danger}
    >
      {children}
    </Button>
  );
};

const DropdownButton = ({ secondary = false, options = [], children, fit }) => {
  const [showList, setShowList] = useState(false);
  const dropdownRef = useRef(null);

  const toggleListVisibility = () => setShowList((prev) => !prev);

  const handleBlur = (event) => {
    if (!dropdownRef.current.contains(event.relatedTarget)) {
      setShowList(false);
    }
  };

  return (
    <div ref={dropdownRef} style={{ position: "relative" }}>
      <Button
        secondary={secondary}
        style={{ position: "relative" }}
        onClick={toggleListVisibility}
        onBlur={handleBlur}
        fit={fit}
      >
        <ExportButtonContainer>
          {children}
          {showList ? <ChevronUp /> : <ChevronDown />}
        </ExportButtonContainer>
      </Button>
      {showList && (
        <ButtonOptions onMouseDown={(e) => e.preventDefault()}>
          {options}
        </ButtonOptions>
      )}
    </div>
  );
};

export { Button, FormikSubmit, DropdownButton };
