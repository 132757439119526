import {
  baseURL,
  Button,
  DatePicker,
  Dropdown,
  OverflowContainer,
  RowFlex,
  Spinner,
  TextStyledLink,
  useAxios,
  useCrmCustomers,
  useExportFile,
} from "common";
import { useState } from "react";
import { Container, DownloadButton, DownloadLink, Viewer } from "./styles";
import CreateCustomer from "./CreateCustomer";

const ExcelView = ({ brand, params: p }) => {
  const { crmCustomers, loading, refetch } = useCrmCustomers();
  const [customer, setCustomer] = useState(p?.customer || "");
  const [proceed, setProceed] = useState(!!p?.customer && !!p?.followUpDate);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [followUpDate, setFollowUpDate] = useState(p?.followUpDate);

  const params = { ...p, customer, followUpDate };

  const { response } = useAxios({
    callOnLoad: { method: "GET", url: "/auth/me/token" },
  });
  const { getThisFile, exportLoading } = useExportFile(
    brand?.name || "BrandItems",
    `/brands/${brand.id}/download`
  );

  if (!response || loading) return <Spinner />;

  const payload = { token: response.data, ...params };
  const url = `${baseURL}/brands/${brand.id}/download?payload=${JSON.stringify(
    payload
  )}`;
  const src = `https://view.officeapps.live.com/op/view.aspx?src=${url}`;
  //can't just make normal query string because anything beyond the token
  //goes to the view.officeapps.live query params

  //doug@abbotteggleston.com

  const closeCustomerCreate = () => setCreateCustomer(false);

  if (createCustomer)
    return (
      <OverflowContainer>
        <CreateCustomer
          cancel={closeCustomerCreate}
          onComplete={(customer) => {
            setCustomer(customer);
            refetch();
            closeCustomerCreate();
          }}
        />
      </OverflowContainer>
    );

  if (!proceed)
    return (
      <OverflowContainer>
        <RowFlex>
          <div>
            <RowFlex>
              <Dropdown
                label="Select Customer"
                required
                disabled={!!p?.customer}
                value={customer}
                onChange={(v) => setCustomer(v)}
                options={crmCustomers}
              />
              <TextStyledLink
                style={{ fontSize: "0.7rem" }}
                onClick={() => setCreateCustomer(true)}
              >
                Or Create New
              </TextStyledLink>
            </RowFlex>
            <br />
            <DatePicker
              label="Follow Up Date (Optional)"
              value={followUpDate}
              onChange={(d) => setFollowUpDate(d)}
            />
            <br />
          </div>
        </RowFlex>
        <Button
          fit
          disabled={!customer}
          secondary
          onClick={() => setProceed(true)}
        >
          Proceed
        </Button>
      </OverflowContainer>
    );

  return (
    <Container>
      <RowFlex>
        {customer && (
          <div>
            Customer:{" "}
            <strong>
              {crmCustomers.find((c) => c.value === customer)?.label}
            </strong>
          </div>
        )}
        <DownloadButton
          loading={exportLoading}
          onClick={() => getThisFile(params)}
        >
          Download
        </DownloadButton>
        <DownloadLink
          to="#"
          onClick={() => getThisFile({ ...params, skipSalonPrice: true })}
        >
          Download without Salon Price
        </DownloadLink>
      </RowFlex>
      <Viewer src={encodeURI(src)} />
    </Container>
  );
};

export default ExcelView;
