import { useAlertModal, useModalContext } from "common";
import React, { useState } from "react";
import ExcelView from "./ExcelView";

const Overseas = ({ margin, brand, onUpdateNetherland, customer }) => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const { setModalContent } = useModalContext();
  const alert = useAlertModal("Please Ensure Up-front Payment");

  const openModal = (params, show = true, childBrand = undefined) => {
    show &&
      setModalContent(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ExcelView brand={childBrand || brand} params={params} />
        </div>
      );
  };

  const handleChange = (event) => {
    const val = event.target.value;
    setSelectedLocation(val);

    if (val === "netherlands") {
      onUpdateNetherland(true);
    } else {
      onUpdateNetherland(false);
    }

    if (val !== "") {
      const open = () =>
        openModal(
          {
            customer,
            margin: margin.margin,
            location: val,
          },
          brand.itemsHaveCostPrice
        );
      if (margin.margin < 12) {
        alert(null, open);
      } else {
        open();
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <p style={{ marginBottom: "10px", fontWeight: "bold" }}>
        Is FOB New Jersey or Netherlands?
      </p>
      <div style={{ marginBottom: "10px" }}>
        <input
          type="radio"
          id="netherlands"
          name="location"
          value="netherlands"
          onChange={handleChange}
          checked={selectedLocation === "netherlands"}
        />
        <label htmlFor="netherlands" style={{ marginLeft: "8px" }}>
          Netherlands
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="newjersey"
          name="location"
          value="newjersey"
          onChange={handleChange}
          checked={selectedLocation === "newjersey"}
        />
        <label htmlFor="newjersey" style={{ marginLeft: "8px" }}>
          New Jersey
        </label>
      </div>
    </div>
  );
};

export default Overseas;
