import { RowFlex } from "common";
import {
  ViewBrandLink,
  ViewCustomersLink,
  ViewOffersLink,
} from "../LinkButtons";
import OfferView from "modules/Crm/OfferView";

const ViewOffers = () => {
  return (
    <OfferView
      isCustomer={false}
      stateElement={
        <RowFlex>
          <ViewCustomersLink />
          <ViewBrandLink />
          <ViewOffersLink />
        </RowFlex>
      }
    />
  );
};

export default ViewOffers;
