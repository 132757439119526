import {
  Button,
  DatePicker,
  DividerHorizontal,
  RowFlex,
  TextField,
  crmCustomerBrandStatuses,
  formatNumber,
  getDateWithHumanReadableTime,
  theme,
  useAxios,
  useModalContext,
} from "common";
import { Fragment, useRef, useState } from "react";
import { FaDownload, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { ViewContainer } from "./styles";
import Tooltip from "rc-tooltip";
import { orderBy } from "lodash";

const getNewNotes = (notes = []) =>
  notes.map((n) => ({
    ...n,
    username: n.user?.name,
  }));

const SoView = ({ so, inline }) => {
  if (!so) return null;
  return (
    <ViewContainer inline={inline}>
      <h4>
        {!inline && "Latest SO: "}
        {so.OrderNumber}
      </h4>
      <RowFlex responsive>
        <div>({so.SalesRepresentative})</div>
        <div>{getDateWithHumanReadableTime(so.OrderDate)}</div>
      </RowFlex>
    </ViewContainer>
  );
};

const PoView = ({ po, inline }) => {
  if (!po) return null;
  return (
    <ViewContainer inline={inline}>
      <h4>
        {!inline && "Latest PO: "}
        {po.PONumber}
      </h4>
      <RowFlex responsive>
        <div>{getDateWithHumanReadableTime(po.PODate)}</div>
      </RowFlex>
    </ViewContainer>
  );
};

const InvoiceView = ({ invoice, inline }) => {
  if (!invoice) return null;
  return (
    <ViewContainer inline={inline}>
      <h4>
        {!inline && "Latest Invoice: "}
        {invoice.InvoiceNumber}
      </h4>
      <div>{getDateWithHumanReadableTime(invoice.InvoiceDate)}</div>
      <div>
        <strong>Total:</strong> ${formatNumber(invoice.TotalAmount)}
      </div>
      <div>
        <strong>Aging:</strong> {formatNumber(invoice.Aging)}
      </div>
    </ViewContainer>
  );
};

const ChangeFollowUpDate = ({
  brand,
  updateCustomerBrandState,
  label,
  inline,
}) => {
  const [followUpDate, setFollowUpDate] = useState(brand.followUpDate);
  const { loading, callAxios } = useAxios({
    alertSuccess: "Follow Up Date Updated",
    onComplete: (res) => {
      updateCustomerBrandState({
        ...brand,
        notes: getNewNotes(res.data.notes),
        followUpDate: res.data.followUpDate,
      });
    },
  });

  return (
    <DatePicker
      inline={inline}
      label={label}
      value={followUpDate}
      allBorders
      fillWidth
      disabled={loading}
      onChange={(v) => {
        setFollowUpDate(v);
        callAxios({
          method: "POST",
          url: `/crmCustomerBrands/${brand.id}/followUp`,
          data: { followUpDate: v },
        });
      }}
    />
  );
};

const AddNote = ({
  brand,
  updateCustomerBrandState,
  setNotes,
  noteIndex,
  value = "",
  canModify,
}) => {
  const isUpdate = !!value;
  const [startUpdate, setStartUpdate] = useState(false);
  const [note, setNote] = useState(value);
  const ref = useRef();
  const { loading, callAxios } = useAxios({
    alertSuccess: `Note ${isUpdate ? "Updated" : "Added"}`,
    onComplete: (res) => {
      const newNotes = getNewNotes(res.data.notes);
      updateCustomerBrandState({
        ...brand,
        notes: newNotes,
      });
      setNote("");
      setNotes(newNotes);
      setStartUpdate(false);
    },
  });

  if (isUpdate && !startUpdate) {
    if (canModify)
      return (
        <RowFlex
          style={{ cursor: "pointer" }}
          onClick={() => setStartUpdate(true)}
        >
          <FaEdit />
          <div dangerouslySetInnerHTML={{ __html: value }} />
        </RowFlex>
      );

    return <div dangerouslySetInnerHTML={{ __html: value }} />;
  }

  return (
    <RowFlex column>
      <TextField
        ref={ref}
        disabled={loading}
        label={!isUpdate ? "Add Note" : <div />}
        value={note}
        isArea
        onChange={(v) => {
          setNote(v);
        }}
      />
      <Button
        disabled={!note || loading}
        onClick={() => {
          const endpoint = `${isUpdate ? "update" : "add"}Note`;
          const noteIndexUrl = isUpdate ? `/${noteIndex}` : "";
          callAxios({
            method: isUpdate ? "PUT" : "POST",
            url: `/crmCustomerBrands/${brand.id}/${endpoint}${noteIndexUrl}`,
            data: { value: note },
          });
        }}
      >
        {isUpdate ? "Update" : <FaPlus />}
      </Button>
    </RowFlex>
  );
};

const DeleteNote = ({
  brand,
  updateCustomerBrandState,
  noteIndex,
  setNotes,
}) => {
  const { loading, callAxios } = useAxios({
    alertSuccess: "Note Deleted",
    onComplete: (res) => {
      const newNotes = getNewNotes(res.data.notes);
      updateCustomerBrandState({
        ...brand,
        notes: newNotes,
      });
      setNotes(newNotes);
    },
  });
  return (
    <FaTrash
      cursor="pointer"
      fill={theme.colors[loading ? "lightGrey" : "danger"]}
      onClick={() => {
        !loading &&
          callAxios({
            url: `/crmCustomerBrands/${brand.id}/deleteNote/${noteIndex}`,
            method: "DELETE",
          });
      }}
    />
  );
};

const Notes = ({ brand, updateCustomerBrandState }) => {
  const [notes, setNotes] = useState(brand.notes);
  const props = { brand, updateCustomerBrandState, setNotes };
  return (
    <RowFlex column style={{ padding: "0.5rem" }}>
      <AddNote {...props} />
      {orderBy(notes, (n) => new Date(n.date), "desc").map((note, idx) => {
        const canModify = !note.disallowDelete;
        return (
          <RowFlex key={note.date + idx} column style={{ fontSize: "0.8rem" }}>
            <RowFlex extend style={{ fontWeight: "bold" }}>
              {note.username || note.user?.name}
              <RowFlex>
                {note.isNoteModified ? " (modified)" : ""}
                <div>{getDateWithHumanReadableTime(note.date)}</div>{" "}
                {canModify && <DeleteNote {...props} noteIndex={idx} />}
              </RowFlex>
            </RowFlex>
            <AddNote
              {...props}
              noteIndex={idx}
              value={note.value}
              canModify={canModify}
            />
            <DividerHorizontal />
          </RowFlex>
        );
      })}
    </RowFlex>
  );
};

const PricesheetDownloads = ({ brand }) => {
  const { setModalContent } = useModalContext();
  if (brand.pricesheetDownloads.length === 0) return null;
  return (
    <Tooltip overlay="Pricesheet Downloads">
      <FaDownload
        cursor="pointer"
        onClick={() =>
          setModalContent(
            <RowFlex column style={{ padding: "0.5rem" }}>
              <div>
                Pricesheet Downloads For{" "}
                <strong>{brand.brandName || brand.brand.name}</strong> from{" "}
                <strong>
                  {brand.customerName || brand.customer?.CustomerName}
                </strong>
                :
              </div>
              <DividerHorizontal />
              {orderBy(
                brand.pricesheetDownloads,
                (d) => new Date(d.date),
                "desc"
              ).map((dl) => {
                return (
                  <Fragment key={dl.date}>
                    <div>
                      <RowFlex extend>
                        <strong>{dl.username || dl.user?.name}</strong>{" "}
                        {getDateWithHumanReadableTime(dl.date)}
                      </RowFlex>
                      Margin: {dl.margin}%
                    </div>
                    <DividerHorizontal />
                  </Fragment>
                );
              })}
            </RowFlex>
          )
        }
      />
    </Tooltip>
  );
};

const RejectModal = ({
  brand,
  updateCustomerBrandState,
  close,
  name,
  isRejected,
}) => {
  const [note, setNote] = useState("");
  const { loading, callAxios } = useAxios({
    alertSuccess: "Brand Rejected",
    onComplete: (res) => {
      updateCustomerBrandState({
        ...brand,
        status: res.data.status,
        notes: getNewNotes(res.data.notes),
        rejectionDate: res.data.rejectionDate,
      });
      close();
    },
  });
  return (
    <div>
      <h3>
        {isRejected ? "Reapprove" : "Reject"} {name}
      </h3>
      <TextField
        disabled={loading}
        label="Add Note"
        value={note}
        isArea
        onChange={(v) => {
          setNote(v);
        }}
      />
      <Button
        disabled={!note || loading}
        onClick={() =>
          callAxios({
            method: "POST",
            url: `/crmCustomerBrands/${brand.id}/reject`,
            data: { note },
          })
        }
      >
        Confirm
      </Button>
    </div>
  );
};

const Reject = ({ brand, updateCustomerBrandState, name }) => {
  const { setModalContent, closeModal } = useModalContext();
  const isRejected = brand.status === crmCustomerBrandStatuses.REJECTED;

  return (
    <Button
      type={!isRejected && "danger"}
      onClick={() => {
        setModalContent(
          <RejectModal
            brand={brand}
            updateCustomerBrandState={updateCustomerBrandState}
            name={name}
            close={closeModal}
            isRejected={isRejected}
          />
        );
      }}
    >
      {isRejected ? "Reapprove" : "Reject"}
    </Button>
  );
};

const AgingView = ({ brand: { aging } }) => {
  const mapping = {
    under30Days: "30 Days",
    under60Days: "60 Days",
    under90Days: "90 Days",
    under120Days: "120 Days",
    over120Days: "120+",
  };
  if (!aging) return null;

  const hasOutstanding = Object.values(aging).some((a) => a > 0);

  return (
    <div style={{ minWidth: "80px", fontSize: "0.75rem" }}>
      {hasOutstanding ? (
        Object.entries(mapping).map(([key, label]) => (
          <div key={key}>
            <strong>{label}: </strong>${formatNumber(aging[key])}
          </div>
        ))
      ) : (
        <strong>Up To Date</strong>
      )}
    </div>
  );
};

export {
  AgingView,
  ChangeFollowUpDate,
  InvoiceView,
  Notes,
  PoView,
  PricesheetDownloads,
  Reject,
  SoView,
};
