import { FullPageLoad, useAxios } from "common";
import React from "react";
import Form from "./Form";

const BrandMatching = () => {
  const { response, refetch, loading } = useAxios({
    callOnLoad: { method: "GET", url: "/crmBrandMatching" },
  });

  if (loading) return <FullPageLoad fillWidth />;

  return (
    <Form
      brands={response?.data.hbaBrands}
      crmBrands={response?.data.crmBrands}
      refetch={refetch}
    />
  );
};

export default BrandMatching;
