import { RowFlex } from "common";
import OrderView from "modules/Crm/OrderView";
import {
  ViewBrandLink,
  ViewCustomersLink,
  ViewOffersLink,
} from "../LinkButtons";

const ViewOrders = () => {
  return (
    <OrderView
      isCustomer={false}
      stateElement={
        <RowFlex>
          <ViewCustomersLink />
          <ViewBrandLink />
          <ViewOffersLink />
        </RowFlex>
      }
    />
  );
};

export default ViewOrders;
