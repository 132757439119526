import { ErrorText, InputLabel } from "common/styles";
import { getIn } from "formik";
import { forwardRef, useState } from "react";
import DateSelect from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import { CalendarIcon, Container, Input } from "./styles";

const DateInput = forwardRef(
  ({ value, onClick, allBorders, disabled }, ref) => (
    <Input
      onClick={onClick}
      ref={ref}
      allBorders={allBorders}
      disabled={disabled}
    >
      {value || "Select Range..."}
      <CalendarIcon />
    </Input>
  )
);

const DateRangePicker = ({
  label,
  required,
  field,
  form,
  disabled,
  fillWidth = false,
  allBorders = false,
  value,
  onChange,
  inline,
  isClearable = true,
}) => {
  const realValue = value || field?.value;
  const [startDate, setStartDate] = useState(realValue?.$gte);
  const [endDate, setEndDate] = useState(realValue?.$lte);
  const errorMessage = getIn(form, `errors.${field?.name}`);

  const isTouched = getIn(form, `touched.${field?.name}`);
  const hasError = !!isTouched && !!errorMessage;

  return (
    <Container
      hasError={hasError}
      fillWidth={fillWidth}
      hasLabel={!!label}
      inline={inline}
    >
      {label && (
        <InputLabel hasError={hasError}>
          {label}
          {required ? " *" : ""}
        </InputLabel>
      )}
      <DateSelect
        selectsRange
        isClearable={isClearable}
        selected={startDate && new Date(startDate)}
        startDate={startDate && new Date(startDate)}
        endDate={endDate && new Date(endDate)}
        disabled={disabled}
        customInput={<DateInput allBorders={allBorders} disabled={disabled} />}
        onChange={([start, end]) => {
          setStartDate(start?.toISOString() || "");
          setEndDate(end?.toISOString() || "");

          const hasAll = !!start && !!end;
          const hasNone = !start && !end;

          if (!hasAll && !hasNone) return;

          const finalValue = hasAll
            ? { $gte: start.toISOString(), $lte: end.toISOString() }
            : null;

          field?.onChange({
            target: {
              name: field?.name,
              value: finalValue,
            },
          });
          onChange?.(finalValue);
        }}
      />
      {hasError && <ErrorText>{errorMessage}</ErrorText>}
    </Container>
  );
};

export default DateRangePicker;
